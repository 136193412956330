// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'select2/dist/css/select2.css'
import 'select2'

import moment from 'moment';
window.moment = moment;
import 'moment-timezone'

import 'daterangepicker';
import 'daterangepicker/daterangepicker.css'; // Import the Date Range Picker CSS

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick';

import DataTable from 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css'

import Sortable from 'sortablejs';
window.Sortable = Sortable

import { scrollIntoView } from "seamless-scroll-polyfill";
window.scrollIntoView = scrollIntoView

import Chart from 'chart.js/auto';
window.Chart = Chart

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

require("alpine-turbolinks-adapter")
require("alpinejs")
require("./animate-alerts.js")
require("./data-tables.js")
require("./matches.js")
require("./closest.js")
require("./check-button.js")
require("./image-upload.js")
require("./mask-input.js")
require("./option-select.js")
require("./stop-transitions.js")
require("./turboload.js")
require("./validation.js")
require("./copy-to-clipboard.js")
require("./native-sharing-mechanism.js")
require("./common.js")

//require("stylesheets/application.css")
require('stylesheets/application.scss')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
import flatpickr from "flatpickr"
import 'controllers'

require("flatpickr/dist/themes/material_green.css")

/**
 * CheckButton 0.0.1
 * Provide CSS hook for checked buttons
 * @author Kyle Foster (@hkfoster)
 * @license MIT
 **/

const checkButton = (() => {
  
  // Run delegated change handler
  document.addEventListener('change', changeHandler)
  document.addEventListener('turbolinks:load', loadHandler)

  // Change event handler
  function changeHandler(event) {
    if (!event.target.matches('.check-button input[type=radio], .check-button input[type=checkbox]')) return false
    checkState(event.target)
  }

  // Document load event handler
  function loadHandler() {
    const activeInputs = document.querySelectorAll(`.check-button [checked]`)
    activeInputs.forEach(input => input.closest('.check-button').setAttribute('data-checked', ''))
  }

  // Set check button state
  function checkState(input) {
    const activeRadio = document.querySelector(`[data-checked] [type=radio][name="${input.name}"]`)
    if (activeRadio) activeRadio.closest('.check-button').removeAttribute('data-checked')
    if (input.checked) input.closest('.check-button').setAttribute('data-checked', '')
  }
})()
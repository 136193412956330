/**
 * MaskInput 0.0.1
 * Minimal input masking
 * @author Kyle Foster (@hkfoster)
 * @license MIT
 **/

const maskInput = (() => {

  // Run delegated event handlers
  document.addEventListener('input', formatHandler, false)
  document.addEventListener('focus', formatHandler, true)
  document.addEventListener('blur', blurHandler, true)

  // Format event handler
  function formatHandler(event) {
    const element = event.target
    if (!element.matches('[data-mask]')) return false
    format(element)
  }

  // Blur event handler
  function blurHandler(event) {
    const element = event.target
    if (!element.matches('[data-mask]')) return false
    if (element.value === element.getAttribute('data-mask')) element.value = ''
  }

  // Parse input
  function parse(x, pattern, mask) {
    const strippedValue = x.replace(/[^0-9]/g, "")
    const chars = strippedValue.split('')
    let count = 0
    let formatted = ''

    for (let i = 0; i < pattern.length; i++) {
      const c = pattern[i]
      if (chars[count]) {
        if (/\*/.test(c)) {
          formatted += chars[count]
          count++
        } else {
          formatted += c
        }
      } else if (mask) {
        if (mask.split('')[i]) {
          formatted += mask.split('')[i]
        }
      }
    }

    return formatted
  }

  // Format input
  function format(elem) {
    const val = parse(elem.value, elem.getAttribute('data-format'))
    elem.value = parse(elem.value, elem.getAttribute('data-format'), elem.getAttribute('data-mask'))
    
    if (elem.createTextRange) {
      elem.setSelectionRange(0,0);
      let range = elem.createTextRange()
      range.move('character', val.length)
      range.select()
    } else if (elem.selectionStart) {
      elem.setSelectionRange(val.length, val.length)
    }
  }
})()

// ======================================== Common event listeners ================================================
document.addEventListener("turbolinks:load", () => {
  InitFlatPicker();
  initSelect2();

  // if(document.getElementsByClassName('sticky-btn').length > 0) {
  //   stickyButton();
  // }

  if(document.getElementsByClassName('validate-fields').length > 0){
    validateFormFields(document.getElementsByClassName('validate-fields')[0]);
    SubmitButtonStateCheck();
  }

});

$(document).on('ajax:success', function(){
  initSelect2()
});

// ================================================ Common functions ================================================
window.InitFlatPicker = function() {
  flatpickr("[data-behavior='flatpickr']:not(.flatpickr-initialized)", {
    altInput: true,
    altFormat: "D M j, Y",
    dateFormat: "Y-m-d",
    static: true,
    position: 'below',
    onReady: function(selectedDates, dateStr, instance) {
      // find parent label span
      var label = this.input.closest('label')
      var parentSpan = $(label).find('span.label')[0];

      // move span to wrapper div
      if(parentSpan){
        var wrapper = $(label).find('.flatpickr-wrapper')[0];
        if(wrapper){
          $(wrapper).append(parentSpan);
        }
      }
      // add class to prevent flatpickr initializing again
      $(instance.input).addClass('flatpickr-initialized');

      $(instance.altInput).prop('readonly', false);
      $(instance.altInput).attr('data-message', this.input.dataset['message']);

      // To set error message in input field in mobile browser
      $(instance.mobileInput).attr('data-message', this.input.dataset['message']);

      // To set min and max date in input field in mobile browser
      $(instance.mobileInput).attr('min', this.input.getAttribute('min'));
      $(instance.mobileInput).attr('max', this.input.getAttribute('max'));

      // To display HTML5 min and max validation error message on mobile
      $(instance.mobileInput).addClass('flatpickr-mobile-input');
      $(instance.mobileInput).attr('data-name', this.input.getAttribute('data-name'));
      $(instance.mobileInput).attr('data-message-position', this.input.getAttribute('data-message-position'));
      $(instance.mobileInput).attr('data-html-validation-required', this.input.getAttribute('data-html-validation-required'));
      $(instance.mobileInput).attr('data-tooltip-size', 'fit')
      $(instance.mobileInput).attr('x-bind:required', this.input.getAttribute('x-bind:required'))
      $(instance.altInput).attr('x-bind:required', this.input.getAttribute('x-bind:required'))
    },
    // to make HTML5 validations work for flatpicker input, ref: https://www.vic-l.com/how-to-use-html-validation-on-flatpickr
    onOpen: function(selectedDates, dateStr, instance) {
      $(instance.altInput).prop('readonly', true);
    },
    onClose: function(selectedDates, dateStr, instance) {
      $(instance.altInput).prop('readonly', false);
      $(instance.altInput).blur();
    }
  });

  flatpickr("[data-behavior='flatpickrdatetime']:not(.flatpickr-initialized)", {
    enableTime: true,
    altInput: true,
    altFormat: "D M j, Y h:i K",
    dateFormat: "Y-m-d H:i",
    static: true,
    position: 'below',
    onReady: function(selectedDates, dateStr, instance) {
      // find parent label span
      var label = this.input.closest('label')
      var parentSpan = $(label).find('span.label')[0];

      // move span to wrapper div
      if(parentSpan){
        var wrapper = $(label).find('.flatpickr-wrapper')[0];
        if(wrapper){
          $(wrapper).addClass('w-full')
          $(wrapper).append(parentSpan);
        }
      }

      // add class to prevent flatpickr initializing again
      $(instance.input).addClass('flatpickr-initialized');

      $(instance.altInput).prop('readonly', false);
      $(instance.altInput).attr('data-message', this.input.dataset['message']);

      // To set error message in input field in mobile browser
      $(instance.mobileInput).attr('data-message', this.input.dataset['message']);

      // To set min and max date in input field in mobile browser
      $(instance.mobileInput).attr('min', this.input.getAttribute('min'));
      $(instance.mobileInput).attr('max', this.input.getAttribute('max'));

      // To display HTML5 min and max validation error message on mobile
      $(instance.mobileInput).addClass('flatpickr-mobile-input');
      $(instance.mobileInput).attr('data-name', this.input.getAttribute('data-name'));
      $(instance.mobileInput).attr('data-message-position', this.input.getAttribute('data-message-position'));
      $(instance.mobileInput).attr('data-html-validation-required', this.input.getAttribute('data-html-validation-required'));
      $(instance.mobileInput).attr('data-tooltip-size', 'fit')
      $(instance.mobileInput).attr('x-bind:required', this.input.getAttribute('x-bind:required'))
      $(instance.altInput).attr('x-bind:required', this.input.getAttribute('x-bind:required'))
    },
    // to make HTML5 validations work for flatpicker input, ref: https://www.vic-l.com/how-to-use-html-validation-on-flatpickr
    onOpen: function(selectedDates, dateStr, instance) {
      $(instance.altInput).prop('readonly', true);
    },
    onClose: function(selectedDates, dateStr, instance) {
      $(instance.altInput).prop('readonly', false);
      $(instance.altInput).blur();
    }
  });

  flatpickr("[data-behavior='flatpickrtime']:not(.flatpickr-initialized)", {
    altInput: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: "h:i K",
    static: true,
    minuteIncrement: 1,
    onReady: function(selectedDates, dateStr, instance) {
      // find parent label span
      var label = this.input.closest('label');
      var parentSpan = $(label).find('span.label')[0];

      // move span to wrapper div
      if (parentSpan) {
        var wrapper = $(label).find('.flatpickr-wrapper')[0];
        if (wrapper) {
          $(wrapper).append(parentSpan);
        }
      }

      // add class to prevent flatpickr initializing again
      $(instance.input).addClass('flatpickr-initialized');
      $(instance.altInput).prop('readonly', false);

      $(instance.altInput).attr('data-message', this.input.dataset['message']);

      // To set error message in input field in mobile browser
      $(instance.mobileInput).attr('data-message', this.input.dataset['message']);
    },
    // to make HTML5 validations work for flatpicker input, ref: https://www.vic-l.com/how-to-use-html-validation-on-flatpickr
    onOpen: function(selectedDates, dateStr, instance) {
      $(instance.altInput).prop('readonly', true);
    },
    onClose: function(selectedDates, dateStr, instance) {
      $(instance.altInput).prop('readonly', false);
      $(instance.altInput).blur();
    }
  });
};

window.DateRangeOnChangeHandler = function(start_date_id, end_date_id) {
  var startDate = document.getElementById(start_date_id)._flatpickr;
  var endDate = document.getElementById(end_date_id)._flatpickr;

  startDate.config.onChange.push(function(date){
    var nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    endDate.clear();

    // Flatpickr uses separate input for desktop and mobile devices
    if(startDate.isMobile) {
      $(endDate.mobileInput).attr('min', moment(nextDay).format('YYYY-MM-DD'));
    } else {
      endDate.set('minDate', nextDay)
    }
  });
};

window.dateRangeMinMaxDate = function({ start_date_id = '', end_date_id = '', start_min_date = null, start_max_date = null, end_min_date = null, end_max_date = null } = {}) {
  if(start_date_id)
    var startDate = document.getElementById(start_date_id)._flatpickr;

  if(end_date_id)
    var endDate = document.getElementById(end_date_id)._flatpickr;

  // To pick the date, flatpickr datepicker is used for desktop and native datepicker is used for mobile devices.
  // So setting the minDate and maxDate options only in desktop browsers is sufficient. For mobile devices, flatpickr
  // uses a separate date type input and the min and max attributes are set when flatpickr is initialized.
  if(!(startDate || endDate).isMobile) {
    if(startDate){
      startDate.set({
        minDate: start_min_date ? new Date(start_min_date) : null, maxDate: start_max_date ? new Date(start_max_date) : null
      })
    }

    if(endDate){
      endDate.set({
        minDate: end_min_date ? new Date(end_min_date) : null, maxDate: end_max_date ? new Date(end_max_date) : null
      })
    }
  }
}

window.initSelect2 = function() {
  $('.select-with-checkbox').not('.select2-dynamic-option-creation').select2({
    tags: false,
    allowClear: true,
    templateResult: formatSelect2Option
  });

  $('.select2-dynamic-option-creation').select2({
    tags: true,
    allowClear: true,
    templateResult: formatSelect2Option
  });

  $('.select-with-checkbox').each(function() {
    if(! $(this).data('select2').options.options.multiple){
      $(this).on('select2:open', function (e) {
        style_select()
      })
    }

    $(this).on('select2:select', function(e){
      // trigger change event to check "validation" on multi-select
      this.dispatchEvent(new Event("change"))
    })

    $(this).on('select2:clearing', function(e){
      this.dispatchEvent(new Event("change"))
    })
  });

  $('.normal-select2').select2(
    {
      theme: 'normal-select2-container'
    }
  );

  $('.lines-qty-select-field').select2(
    {
      minimumResultsForSearch: -1,
      theme: 'normal-select2-container',
      dropdownCssClass: "lines-quantity-dropdown"
    }
  ).on('select2:open', () => {
    $(".select2-results:not(:has(a))").append('<a href="#" class="select2-results__option select2-results__option--selectable custom-quantity-option">Custom</a>');
  })

  $('.normal-select2').each(function() {
    if(! $(this).data('select2').options.options.multiple) {
      $(this).on('select2:open', function (e) {
        style_select()
      })
    }
  });
};

function formatSelect2Option (select_option) {
  if (!select_option.id) {
    return select_option.text;
  }
  return $(
    '<span><div class="inline-flex items-center">\n' +
    '                  <label for="campaign_product_id_1" class="flex items-center cursor-pointer">\n' +
    '                    <span class="w-4 text-white h-4  select2-checkbox  mr-1  rounded border border-grey">\n' +
    '                      <i class=" font-light fas fa-check text-xs" style="font-weight: 400" aria-hidden="true"></i>\n' +
    '                    </span>\n' +
    '</label>\n' +
    '\n' +
    ' </div> ' + select_option.text + '</span>'
  );
}

function style_select() {
  $(".select2-search--dropdown").append('<i class="far fa-search text-light absolute top-1/2 left-2 transform -translate-y-1/2 select2-icon"></i>')
    .css("position","relative");
  $(".select2-search__field").css("padding-left", "30px");
}

window.addValidation = function(field_id, message, find_by_id) {
  function showErrorMessage(element) {
    // If invalid is not hidden, focus it
    if (element.style.display !== 'none') {
      if (typeof element.select === 'function') {
        element.select()
      } else {
        element.focus()
      }
    }

    var indicator = closestLabelOrDiv(element)
    if(indicator){
      const oldMessage = indicator.querySelector('[data-validation-message]')
      if(oldMessage)
        oldMessage.parentNode.removeChild(oldMessage)

      indicator.removeAttribute('data-valid');
      indicator.setAttribute('data-invalid', '');
      indicator.setAttribute('backend-error','');
      indicator.insertAdjacentHTML('beforeend',
        `<aside data-validation-message data-tooltip data-tooltip-position="${element.getAttribute('data-message-position') || 'down'}" data-tooltip-visible="true"><span data-tooltip-content data-tooltip-size="${element.getAttribute('data-tooltip-size')}">${message}</span></aside>`
      );
    }
  }

  if(find_by_id) {
    showErrorMessage(document.getElementById(field_id));
  } else if(field_id.includes('custom-donation-amounts-')) {
    document.getElementsByClassName(field_id).forEach((element) => showErrorMessage(element))
  }
};

window.scrollToElement = function (form_id) {
  var indicator = document.getElementById(form_id).querySelector('[data-invalid], [backend-error]');
  var container = $(".form-container");
  var element = $(indicator).find('input')[0];

  if(container && indicator){
    // Calculating new position of scrollbar
    var position = $(indicator).offset().top - 30
      - container.offset().top
      + container.scrollTop();

    container.animate({ scrollTop: position }, 600);
  }
  // Focus the first error field.
  if (element && element.style.display !== 'none') {
    if (typeof element.select === 'function') {
      element.select()
    } else {
      element.focus()
    }
  }
};

window.set_x_data = function (element, key, value) {
  // Fetch x-data. Remove 'new line', 'space', 'tab space', '{', '}'. Split into array.
  var data_array = $(element).attr('x-data').replace(/\n/g, "").replace(/ /g,'').replace(/\t/,'').replace('{','').replace('}','').split(',');
  if(data_array && key){
    $.each(data_array, function(i, pair) {
      // To check if the pair starts with 'key:'
      if (pair.indexOf(key+':') === 0) {
        if(value) { data_array[i] = key + ': ' + value; }
      }
    });
    $(element).attr('x-data', '{' + data_array.join(',') + '}')
  }
};


window.displayFlash = function(message, type){
  var klass;
  if(type === 'alert')
    klass = '.alert';
  else
    klass = '.notice';

  $(klass).removeClass('hidden');
  $(klass).text(message);
  setTimeout(function() {
    $(klass).addClass('hidden');
  }, 2000);
};

function validateFormFields(form){
  var fields;
  var valid_fields = 0;
  var submit_button = $(form).find('[type=submit].button')[0] || $(form).find('.submit-button')[0];
  fields = form.querySelectorAll('[required]');
  $.each(fields, function (i, field) {
    var validity = field.checkValidity();
    if(validity){
      valid_fields++;
    }
  });
  if(valid_fields === fields.length){
    $(submit_button).removeClass("text-theme_gray-500 bg-theme_gray-400 bg-opacity-60");
    $(submit_button).addClass("button-solid-blue")
  }else {
    $(submit_button).removeClass("button-solid-blue");
    $(submit_button).addClass("text-theme_gray-500 bg-theme_gray-400 bg-opacity-60")
  }
};

function SubmitButtonStateCheck(){
  document.querySelectorAll('.validate-fields [required="required"]').forEach(function (element) {
    element.addEventListener("input", validationHandler, false);
    element.addEventListener("change", validationHandler, false)
  })
}

function validationHandler(event){
  var element = event.target;
  var form = element.form;
  setTimeout(function () {
    validateFormFields(form)
  }, 100);
}

$(document).on('click', '.request-info-icon', function (){
  var line_item_id = $(this).attr('data-line-item-id')
  $.ajax({
    type: "GET",
    url: "/line_items/" + line_item_id + "/show_questions_and_answers",
    dataType: "script"
  });
})

// function stickyButton() {
//   var sticky_button = $('.sticky-btn')
//   var add_class = sticky_button[0].getAttribute('data-add-class')
//   var remove_class = (sticky_button[0].getAttribute('data-remove-class') || '')
//
//   if(sticky_button.length === 1) {
//     var div_top = sticky_button.offset().top;
//
//     $(window).scroll(function() {
//       var window_top = $(window).scrollTop() - 0;
//       if (window_top > div_top) {
//         if (!sticky_button.is('.fixed')) {
//           sticky_button.addClass(add_class);
//           sticky_button.removeClass(remove_class);
//         }
//       } else {
//         sticky_button.removeClass(add_class);
//         sticky_button.addClass(remove_class);
//       }
//     });
//   }
// }

window.searchResultsDropdown = function () {
  var parentElement = $(".drop-results");
  var list = $('.multi-team-schools-select2').select2({
    ajax: {
      url: '/search_schools',
      dataType: 'json',
      processResults: function (data, params) {
        return {
          results: $.map(data, function(value, index){
            return {id: value.id, text: value.name + ' ' + value.category, slug: value.slug};
          })
        }
      }
    },
    minimumInputLength: 5,
    templateResult: schoolDropdownFormat,
    selectionCssClass:'search-dropdown',
    containerCssClass: 'search-dropdown',
    dropdownParent: parentElement,
    allowClear: true,
    closeOnSelect: false,
    language: { noResults: () => "<div class='text-darker-blue'>We couldn't find a match for \"" + document.getElementsByClassName("select2-search__field")[0].value + "\". Please try another search.</div> <div>Can't find what you're looking for? Contact us at <a href=\"tel:2056232812\">205-623-2812</a>.</div>"},
    escapeMarkup: function(markup) {
      return markup;
    }
  }).on("select2:closing", function(e) {
    e.preventDefault();
  }).on("select2:closed", function(e) {
    list.select2("open");
  });

  list.select2("open");

  $('.multi-team-schools-select2').on('select2:open', function (e) {
    $('.search-dropdown').hide()
  });

  $('.multi-team-schools-select2').on('select2:close', function (e) {
    $('.search-dropdown').show()
  });

  $('.select2-selection__rendered').hide()
  $('.select2-selection--single').hide()
  $('.select2-results__options').hide()
}

function schoolDropdownFormat (school) {
  if (school.loading) {
    return school.text;
  }

  var school_name = school.text;
  var input = document.getElementsByClassName('select2-search__field')[0].value;
  var start_index = school_name.toLowerCase().indexOf(input.toLowerCase())
  var end_index = start_index + document.getElementsByClassName('select2-search__field')[0].value.length


  if(start_index == 0){
    var name_1 = ''
  }
  else{
    var name_1 = school_name.substring(0,start_index)
  }
  
  if(school_name[start_index - 1] == ' ' || school_name[start_index] == ' '){
    var space_1 = ' '
  }
  else{
    var space_1 = ''
  }

  var name_2 = school_name.substring(start_index, end_index)

  if(school_name[end_index] == ' ' || school_name[end_index - 1] == ' '){
    var space_2 = ' '
  }
  else{
    var space_2 = ''
  }

  var name_3 = school_name.substring(end_index);


  var $container = $(
      "<a href='/schools/" + school.slug + "/store' target='_blank' class='schools-result-dropdown' id='" + school.slug + "'><div class='text-black  inline-block'>" + name_1 + "</div>" + space_1 + "<div class='text-light-gray-2 inline-block'>" + name_2 + "</div>" + space_2 + "<div class='text-black  inline-block'>" + name_3 + "</div></a>"
  );
  return $container;
}

window.customDropdown = function(){
  $( ".custom-school-search" ).toggle(
      function() {
        $( this ).addClass( "block" );
      },function() {
        $( this ).removeClass( "hidden" );
        document.getElementsByClassName("select2-search__field")[0].focus()
      });
}

$(document).on('input', '.custom-school-search .select2-search__field', function () {
  if(document.getElementsByClassName("select2-search__field")[0].value.length >= 5 || document.getElementsByClassName('select2-results__option')[0].innerText.includes("find a match")){
    $('.select2-results__options').show()
  }
  else{
    $('.select2-results__options').hide()
  }
})

$(document).on('click', '#school-search-btn', function () {
  if(document.getElementsByClassName("schools-result-dropdown").length > 0){
    var slug = document.getElementsByClassName("schools-result-dropdown")[0].id
    var url = '/schools/' + slug + '/store'
    window.open(url, '_blank')
  }
})

window.move_position = function(element){
  scrollIntoView(document.getElementById(element.getAttribute('data-id')), {
    behavior: "smooth"
  });
}

window.toggleActionButtons = function(class_name, show){
  if(show){
    $('.' + class_name).find('.action-buttons').removeClass('md:invisible')
  }else{
    $('.' + class_name).find('.action-buttons').addClass('md:invisible')
  }
}

window.defaultOptions = function(){
  $.extend(true, $.fn.dataTable.defaults, {
    order: [['1', 'desc']],
    pageLength: 25,
    language: {
      lengthMenu: '<select>'+
          '<option value="10">10</option>'+
          '<option value="25">25</option>'+
          '<option value="50">50</option>'+
          '<option value="100">100</option>'+
          '</select> <p class="md:inline-block hidden">entries per page</p><p class="md:hidden inline-block">entries</p>',
      infoFiltered: '',
      'search' : '',
      searchPlaceholder: "Search....",
      paginate: {
        next: '<svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" aria-label="Last Page"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>', // or '→'
        previous: '<svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" aria-label="First Page"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>'
      }
    }
  });
}

window.clearFields = function(){
  Array.prototype.forEach.call(document.querySelectorAll('.clearable-input'), function(el) {
    var input = (el.querySelector('input') || el.querySelector('textarea'));

    conditionallyHideClearIcon();
    input.addEventListener('input', conditionallyHideClearIcon);
    el.querySelector('[data-clear-input]').addEventListener('click', function(e) {
      input.value = '';
      input.dispatchEvent(new Event('input'));
      conditionallyHideClearIcon();
    });

    function conditionallyHideClearIcon(e) {
      var target = (e && e.target) || input;
      target.nextElementSibling.style.display = target.value ? 'block' : 'none';
    }
  });
}

window.closestLabelOrDiv = function(element){
  if(element.hasAttribute('data-error-message-indicator')) {
    return document.getElementById(element.getAttribute('data-error-message-indicator'))
  } else {
    return element.closest('label') || element.closest('.trix-div');
  }
}

window.initDateRangePicker = function (timezone, start_count, end_count, future_days_count = 0) {
  var start = moment().tz(timezone).subtract(start_count, 'days');
  var end = moment().tz(timezone).subtract(end_count, 'days');
  var max_date = moment().tz('Etc/UTC').add(future_days_count, 'days')
  var start_date = null
  var end_date = null

  function change_date_range(start, end) {
    if(start_date === null || end_date === null){
      document.getElementsByClassName('date-range-filter').forEach(function(element) {
        element.value = start.format("M-DD-YYYY") + ' to ' + end.format("M-DD-YYYY")
      })
    }

    start_date = start
    end_date = end
  }

  $('.date-range-filter').daterangepicker({
    autoApply: true,
    startDate: start,
    endDate: end,
    ranges: {
      'Last 30 Days': [moment().tz(timezone).subtract(29, 'days'), moment().tz(timezone)],
      'Last 60 Days': [moment().tz(timezone).subtract(59, 'days'), moment().tz(timezone)],
      'Last 90 Days': [moment().tz(timezone).subtract(89, 'days'), moment().tz(timezone)],
    },
    opens: 'center',
    linkedCalendars: false,
    maxDate: max_date
  }, change_date_range);

  change_date_range(start, end);

  $('.date-range-filter').on('apply.daterangepicker', () => {
    if(start_date != null || end_date != null){
      document.getElementsByClassName('date-range-filter').forEach(function(element) {
        $('#' + element.id).data('daterangepicker').setStartDate(start_date);
        $('#' + element.id).data('daterangepicker').setEndDate(end_date);
        element.value = start_date.format("M-DD-YYYY") + ' to ' + end_date.format("M-DD-YYYY")
      })
    }

    reloadOrdersTable()
    reloadPayoutsTable()
    reloadDataTable()
  });
}

window.changeTooltipHtml = function (text) {
  document.getElementsByClassName('copy-qr-code').forEach(function(element){
    element.innerHTML = '<i class="fas fa-spinner fa-pulse"></i> ' + text
  })
}

$(document).on('change', '#sg_select_field', function() {
  $.ajax({
    type: "GET",
    url: '/schools/' + this.getAttribute('data-school-id') + '/products/change_activity_code?sg_id=' + this.value,
    dataType: "script"
  });
})

window.resizeTextArea = function(textarea) {
  textarea.style.height = ""; /* Reset the height*/
  textarea.style.height = textarea.scrollHeight + "px";
}

window.initSortable = function(div) {
  Sortable.create(div, {
    handle: '.move-position',
    animation: 150,
    onEnd: function (evt) {
      reorderQuestions(evt.item.closest('.custom-questions-container'))
    }
  });
}

window.resizeAllTextAreas = function() {
  $('.custom-question-text-area').each(function () {
    resizeTextArea(this)
  })
}

window.addToCart = function() {
  form = document.querySelector('#add-to-cart').form;
  form.dispatchEvent(new Event('submit', {bubbles: true}))
}

window.prependProtocol = function(urlField) {
  if(urlField.value.indexOf('http') != 0) {
    urlField.value = "https://" + urlField.value
  }
}
document.addEventListener('trix-initialize', event => {
  let linkButton = event.target.toolbarElement.querySelector('input[value="Link"]')
  let urlField = event.target.toolbarElement.querySelector('input[name="href"]')
  urlField.addEventListener('keydown', (e) => {
    if(e.key == 'Enter') {
      prependProtocol(urlField)
    }
  })
  linkButton.addEventListener('click', () => {
    prependProtocol(urlField)
  })
})

window.updateSharedCount = function (column, update_path) {
  $.ajax({
    type: "PATCH",
    url: update_path,
    data: { "column_name": column },
    dataType: "script"
  });
}

window.openScheduleModal = function(url) {
  $.ajax({
    type: "GET",
    url: url,
    dataType: "script"
  });
}

window.needToUpdateScheduleDate = function(element, date_field_name) {
  set_x_data(document.getElementById(element.id.replace(date_field_name, '') + 'date_range_container'), 'is_' + date_field_name + '_changed', (element.value != (element.getAttribute('data-date') || '').slice(1, -1)).toString())
}
/**
 * Turboload 0.0.1
 * Make sure Turbolinks fires correctly
 * @author Kyle Foster (@hkfoster)
 * @license MIT
 **/

document.addEventListener('readystatechange', () => {
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    const event = document.createEvent('Event');
    event.initEvent('turbolinks:load', true, true);
    document.dispatchEvent(event);
  }
})
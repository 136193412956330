document.addEventListener("turbolinks:load", function() {

  window.openNativeSharingMechanism = async function({ title = '', text = '', url = '' }) {
    //check if native sharing is available
    if (navigator.share) {
      try {
        const shareData = {
          title: title,
          text: text,
          url: url,
        }
        await navigator.share(shareData);
        displayFlash('Share successful', 'notice');
      } catch (err) {
        displayFlash('Error: ' + err, 'alert')
      }
    } else {
      displayFlash('Native Web Sharing not supported', 'alert');
    }
  }

});

/**
 * DataTables 0.0.1
 * Simple Datatable project integration
 * @author Kyle Foster (@hkfoster)
 * @license MIT
 **/

import { DataTable } from 'simple-datatables'

const dataTables = (() => {

  const initDataTables = () => {
    const tables = document.querySelectorAll('[data-table]');
    const common_options = {
      prevText: '<svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" aria-label="First Page"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>',
      nextText:  '<svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" aria-label="Last Page"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>'
    }
    var perPage = 25
    var perPageSelect = [10, 25, 50, 100]

    if (tables.length) {
      tables.forEach(function(table){
        if(table.id == 'campaigns_pick_product'){
          perPage = 5
          perPageSelect = [5, 10, 25, 50, 100]
        }

        new DataTable(table, {
          common_options,
          perPage: perPage,
          perPageSelect: perPageSelect,
          labels: {
            placeholder: (table.getAttribute('data-search') || "Search....")
          }
        })
      })
    }
  }

  document.addEventListener('turbolinks:load', initDataTables)

})()
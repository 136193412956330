/**
 * OptionSelect 0.0.1
 * Provide CSS hook for selected options
 * @author Kyle Foster (@hkfoster)
 * @license MIT
 **/

const optionSelect = (() => {
  
  // Run delegated change handler
  document.addEventListener('change', changeHandler)
  document.addEventListener('turbolinks:load', loadHandler)

  // Change event handler
  function changeHandler(event) {
    if (event.target.tagName !== 'SELECT') return false
    selectState(event.target)
  }

  // Document load event handler
  function loadHandler() {
    const selects = document.querySelectorAll('select') 
    selects.forEach(select => selectState(select))
  }

  // Set select state
  function selectState(select) {
    if (select.value !== '') {
      select.setAttribute('data-selected', '')
    } else {
      select.removeAttribute('data-selected')
    }
  }
})()
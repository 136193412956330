document.addEventListener("turbolinks:load", function() {

  //If you want to copyText from Element
  window.copyTextFromElement = function (elementID, show_notice = true, id = null) {
    let element = document.getElementById(elementID); //select the element
    let elementText = element.textContent.trim(); //get the text content from the element
    copyText(elementText); //use the copyText function below

    if(show_notice){
      if(id == null){
        let notice = document.getElementsByClassName('notice');
        notice[0].innerText = "Text Copied!"
      }else if(id.includes('_copy_link_mobile')){
        document.getElementById(id).innerText = "Link copied"
      }else{
        document.getElementById(id).innerText = "Copied"
      }
    }
  };

  //If you only want to put some Text in the Clipboard just use this function
  // and pass the string to copied as the argument.
  function copyText(text) {
    navigator.clipboard.writeText(text);
  }

  window.changeText = function(class_name, text, mobile_text){
    document.getElementsByClassName(class_name).forEach(function(element){
      var tooltip_text = ''

      if(element.classList.contains('mobile-ui') && element.innerText != mobile_text){
        tooltip_text = mobile_text
      }else if(!element.classList.contains('mobile-ui') && element.innerText != text){
        tooltip_text = text
      }

      if(tooltip_text != ''){
        setTimeout(function() {
          element.innerText = tooltip_text
        }, 100)
      }
    })
  }

});

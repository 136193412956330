/**
 * AnimateAlerts 0.0.1
 * Provide CSS hook for checked buttons
 * @author Kyle Foster (@hkfoster)
 * @license MIT
 **/

const animateAlerts = (() => {
  
  // Run delegated change handler
  document.addEventListener('turbolinks:load', loadHandler)
  
  // Document load event handler
  function loadHandler() {
    const alert = document.querySelector('.notice:not(:empty), .alert:not(:empty)')
    if (!alert) return false
    
    setTimeout(() => { 
      alert.classList.remove('-translate-x-4') 
      alert.classList.add('hidden')
    }, 3000 )
  }

})()

/**
 * ImageUpload 0.0.1
 * User-friendly TailwindCSS image drag-n-drop
 * @author Kyle Foster (@hkfoster)
 * @license MIT
 **/

const imageUpload = (() => {
  
  document.addEventListener('drop', dropHandler)
  document.addEventListener('dragover', dragOverHandler)
  document.addEventListener('dragleave', dragLeaveHandler)
  document.addEventListener('dragenter', dragEnterHandler)
  
  document.addEventListener('click', deleteImageClickHandler)
  document.addEventListener('click', browseButtonClickHandler)
  document.addEventListener('click', editUploadClickHandler )
  document.addEventListener('change', hiddenInputChangeHandler)
    
  // Store selected file(s)
  let selectedFiles = {}
    
  // Check if file is an image and prepend the initialied template to the target element
  function addFile(target, file) {
    const isImage = file.type.match('image.*')
    const objectURL = URL.createObjectURL(file)
    const clone = document.querySelector('.image-template').content.cloneNode(true)
  
    clone.querySelector('.image-name').textContent = file.name
    clone.querySelector('.image-figure').id = objectURL
    clone.querySelector('.image-delete').dataset.target = objectURL
    clone.querySelector('.image-size').textContent =
      file.size > 1024
        ? file.size > 1048576 ? Math.round(file.size / 1048576) + 'mb' : Math.round(file.size / 1024) + 'kb'
        : file.size + 'b'
  
    isImage &&
      Object.assign(clone.querySelector('img'), {
        src: objectURL,
        alt: file.name
      })
  
    document.querySelector('.browse-button').tabIndex = -1
    document.querySelector('.browse-button').style.pointerEvents = 'none'
    document.querySelector('.empty-image').classList.add('hidden')
    target.prepend(clone)
  
    selectedFiles[objectURL] = file
  }
    
  // Pass click to hidden <input type=file> and capture the selected file
  function browseButtonClickHandler(event) {
    if (!event.target.classList.contains('browse-button')) return false
    document.querySelector('.hidden-image-input').click()
  }

  // Activate uploader when editing
  function editUploadClickHandler(event) {
    if (!event.target.classList.contains('edit-upload')) return false
    const attachment = event.target.hasAttribute('data-image-field-count') ? document.querySelector('#image_field_count_' + event.target.getAttribute('data-image-field-count')) : document.querySelector('.upload-attachment')
    const actions = document.querySelector('.upload-actions')
    const previous_element = attachment.previousElementSibling

    attachment.classList.add('hidden')
    if (previous_element.classList.contains('remove-image'))
      previous_element.value = true

    const element = document.getElementsByName('images_to_be_removed')
    const length = element.length
    if(length > 0)
      element[length - 1].value = element[length - 1].value + ',' + event.target.getAttribute('data-attachment-id')
    actions.classList.remove('hidden')
  }

  function hiddenInputChangeHandler(event) {
    if (!event.target.classList.contains('hidden-image-input')) return false
    for (const file of event.target.files) {
      addFile(document.querySelector('.image-gallery'), file)
    }
  }
  
  // Use to check if a file is being dragged
  const hasFiles = ({ dataTransfer: { types = [] } }) => types.indexOf('Files') > -1

  // Use for dragenter and dragleave events to show if the outermost parent is dragged over
  let counter = 0
  
  // Reset counter and append file to gallery when dropped
  function dropHandler(event) {
    if (!event.target.closest('.image-upload')) return false
    event.preventDefault()

    const gallery = document.querySelector('.image-gallery')
    
    for (const file of event.dataTransfer.files) {
      
      // If file is already selected, clear it out
      if (Object.keys(selectedFiles).length) {
        while (gallery.children.length > 0) gallery.lastChild.remove()
        selectedFiles = {}
        document.querySelector('.empty-image').classList.remove('hidden')
        gallery.append(document.querySelector('.empty-image'))
      } 
      
      addFile(gallery, file)
  
      document.querySelector('.image-overlay').classList.add('opacity-0')
      counter = 0
    }
  }
  
  // Only react to actual files being dragged
  function dragEnterHandler(event) {
    if (!event.target.closest('.image-upload')) return false
    event.preventDefault()
    if (!hasFiles(event)) return
    ++counter && document.querySelector('.image-overlay').classList.remove('opacity-0')
  }
  
  function dragLeaveHandler(event) {
    if (!event.target.closest('.image-upload')) return false
    1 > --counter && document.querySelector('.image-overlay').classList.add('opacity-0')
  }
  
  function dragOverHandler(event) {
    if (!event.target.closest('.image-upload')) return false
    if (hasFiles(event)) event.preventDefault()
  }

  // Image delete handler
  function deleteImageClickHandler(event) {
    if (!event.target.classList.contains('image-delete')) return false
    const ou = event.target.dataset.target
    document.getElementById(ou).remove(ou)
    document.querySelector('.image-gallery').children.length === 1 && document.querySelector('.empty-image').classList.remove('hidden')
    delete selectedFiles[ou]
    document.querySelector('.browse-button').tabIndex = 0
    document.querySelector('.browse-button').style.pointerEvents = 'auto'
  }
})()
/**
 * Validation 0.1.2
 * Native HTML5 form validation
 * @author Kyle Foster (@hkfoster)
 * @license MIT
 **/

// Public API object
const validation = (() => {

  // Suppress default message bubbles
  document.addEventListener('invalid', function (event) {
    event.preventDefault()
  }, true)

  // Run delegated `on blur` validation checks
  document.addEventListener('blur', blurHandler, true)

  // Run delegated `on change` validation checks
  document.addEventListener('change', blurHandler, true)

  // Run delegated `on input` validation checks
  document.addEventListener('input', blurHandler, true)

  // Run delegated `on submit` validation checks
  document.addEventListener('click', submissionHandler, true)

  // Check validity handler function
  function check(element) {

    // Declare status indicator element
    const indicator = closestLabelOrDiv(element) || element

    // If element only contains whitespace, strip value
    if(element.classList.contains('trix-editor-input-area')){
      element = element.closest('.trix-div').getElementsByTagName('input')[0]
      // if(element.value.replace(/<\/?[^>]+>/gi, '').replace(/\s/g, ''))
      //   element.value = ''
    }else{
      if (element.value && !element.value.replace(/\s/g, '').length) element.value = ''
    }

    // Account for custom rejected values
    if (element.getAttribute('data-reject') === element.value) {
      element.setCustomValidity(element.getAttribute('data-reject-message'))
    } else if (element.getAttribute('data-reject') !== element.value) {
      element.setCustomValidity('')
    }

    // Account for required matching inputs
    if (element.getAttribute('data-match')) {
      
      // Cache match target
      const matchTarget = document.querySelector(`#${element.getAttribute('data-match')}`)
      
      // Run match check
      if (matchTarget && (element.value !== matchTarget.value || matchTarget.parentNode.hasAttribute('data-invalid'))) {
        element.setCustomValidity(element.getAttribute('data-message') ? element.getAttribute('data-message') : 'Enter matching passwords')
      } else {
        element.setCustomValidity('')
      }
    }

    // Remove pre-existing validation message
    message('hide', element)

    // Check validity
    const validity = element.checkValidity()

    // If the element has a value but is invalid
    if (element.value && !validity) {
      indicator.removeAttribute('data-valid')
      indicator.setAttribute('data-invalid', '')
    }

    // If the element has a value and is valid
    else if (element.value && validity) {
      indicator.removeAttribute('backend-error')
      indicator.removeAttribute('data-invalid')
      indicator.setAttribute('data-valid', '')
    }

    // If the element has no value
    else if (!element.value) {
      indicator.removeAttribute('data-valid')
      indicator.removeAttribute('data-invalid')
    }

    return validity
  }

  // Validation message handler functions
  function message(action, element) {

    // Scoped variables
    // Remove the nearest error message on blur event.
    const parentLabel = closestLabelOrDiv(element)
    // const parentForm =  element.closest('form')
    const oldMessage = parentLabel.querySelector('[data-validation-message]')

    if(element.classList.contains('flatpickr-mobile-input')) {
      if(element.validity['rangeUnderflow']) {
        var date = element.getAttribute('min').split('-')
        var newMessage = element.getAttribute('data-name') + ' must be greater than or equal to ' + date[1] + '/' + date[2] + '/' + date[0]
      } else if(element.validity['rangeOverflow']) {
        var date = element.getAttribute('max').split('-')
        var newMessage = element.getAttribute('data-name') + ' must be less than or equal to ' + date[1] + '/' + date[2] + '/' + date[0]
      } else {
        var newMessage = element.getAttribute('data-message')
      }
    } else {
      var newMessage = element.getAttribute('data-reject') === element.value ? element.validationMessage : element.getAttribute('data-message') ? element.dataset.message : element.validationMessage
    }

    // Hide old message
    const hideMessage = function () {
      oldMessage.parentNode.removeChild(oldMessage)
    }

    // Show new message
    const showMessage = function () {

      // Find the element's parent label
      const labelParent = closestLabelOrDiv(element)

      // If it doesn't exist, abort
      if (!labelParent) return false

      // Otherwise, create and append the validation message
      labelParent.insertAdjacentHTML('beforeend', `<aside data-validation-message data-tooltip data-tooltip-position="${element.getAttribute('data-message-position') || 'down'}" data-tooltip-visible="true"><span data-tooltip-content data-tooltip-size="${element.getAttribute('data-tooltip-size')}">${newMessage}</span></aside>`)

    }

    // If hide action is passed hide old message
    if (oldMessage && action === 'hide') hideMessage()

    // If show action is passed and no old message exists, show new message
    if (action === 'show' && !oldMessage) {
      showMessage()
    }

    // If show action is passed and old message exists
    else if (action === 'show' && oldMessage) {

      // Make sure old message is not on currently invalid element, then hide old message and show new message
      if (oldMessage.parentNode !== element.parentNode) {
        hideMessage()
        showMessage()
      }

      // Otherwise update message text on currently invalid element
      else {
        oldMessage.childNodes[0].textContent = newMessage
      }
    }
  }

  // Blur validation handler
  function blurHandler(event) {

    // Cache target
    const element = event.target
    // To remove "backend error" on blur event - the element with error need not always has to have 'required'
    const indicator = closestLabelOrDiv(element) || element

    // Run on required, non-submit inputs and the element with backend error(need not always has to have 'required')
    if ((!element.matches('[required]:not([type=submit])'))&&(!element.matches('[data-html-validation-required]:not([type=submit])'))&&(!indicator.matches('[backend-error]:not([type=submit])'))) return

    // Check validity and set appropriate attribute
    check(element)
  }

  // Validation warning handler
  function warn(element) {

    // If invalid is not hidden, focus it
    if (element.style.display !== 'none') {
      if (typeof element.select === 'function') {
        element.select()
      } else {
        element.focus()
      }
    }

    // Otherwise focus its immediate sibling (mostly used for upload buttons)
    else {
      element.nextSibling.select()
    }

    // Show error message
    message('show', element)
  }

  // Submission validation handler function
  function submissionHandler(event) {

    // Cache target
    const element = event.target

    // Only run on submission
    if (!element || !element.matches('[type=submit]') || element.matches('[formnovalidate]')) return

    // Check validity and set appropriate attributes/state
    const invalidForm = element.hasAttribute('form') ? document.getElementById(element.getAttribute('form')) : element.closest('form')
    const requiredInputs = invalidForm.querySelectorAll('[required], [data-html-validation-required]')
    if (requiredInputs.length) requiredInputs.forEach(input => check(input))
    const invalidInput = invalidForm.querySelector('[required]:invalid, [data-html-validation-required]:invalid')

    // If invalid element found
    if (invalidInput) {

      // Set indicator to parent label if it exists
      const indicator = invalidInput.closest('label') || invalidInput

      // Prevent default behavior
      event.preventDefault();

      // Toggle classes
      indicator.removeAttribute('data-valid');
      indicator.setAttribute('data-invalid', '');

      // Scroll to error field
      var container = $(".form-container");
      if(container.length > 0){
          // Calculating new position of scrollbar
          var position = $(indicator).offset().top - 30
              - container.offset().top
              + container.scrollTop();

        container.animate({ scrollTop: position }, 600);
      }

      // Show validation warning
      warn(invalidInput);

      // Prevent Safari submission
      return false
    }
  }

  return {
    check: check,
    warn: warn,
    message: message,
    submission: submissionHandler
  }
})()
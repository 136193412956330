/**
 * StopTransitions 0.0.1
 * Stop all transitions on page load
 * @author Kyle Foster (@hkfoster)
 * @license MIT
 **/

document.addEventListener('turbolinks:load', function(event) {
  setTimeout(function() {
    document.body.classList.remove('stop-transitions')
  }, 100)
})